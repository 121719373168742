.chat-widget.plivo {
    min-height: 100px;
    width: 35%;
    border-radius: 0;
    border: 0;
    height: auto;
    max-height: 70%;
    bottom: 0;
    z-index: 9;
}

.chat-widget.plivo .chat-icon {
    right: 5px;
    position: absolute;
    height: 61px;
    display: flex;
    align-items: center;
}

.dial-call-plivo .call {
    margin: 0 20px 0 0px;
}

.dial-call-plivo .call .call-icon {
    border-radius: 100%;
    color: #1b468e;
    width: 30px;
    height: 30px;
    background-color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.chat-widget.plivo .chat-content {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.dial-call-plivo .border-0.card {
    background: transparent;
    box-shadow: none;
    border: 0;
}

.dial-call-plivo .border-0.card p {
    margin: 0;
    text-align: left;
    color: #fff;
}

.chat-icon button.close-btn {
    border-radius: 50%;
    padding: 0;
    line-height: normal;
    height: 25px;
    width: 25px;
    font-size: 10px;
}

.answren-call {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.answren-call .call {
    display: flex;
    column-gap: 10px;
}

.answren-call {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    padding: 0 40px 0 10px;
}

.answren-call button.keypad-button {
    width: 25px;
    height: 25px;
    font-size: 10px;
    border-radius: 50%;
}

.bg-transparent p {
    margin: 0;
    color: #fff;
}

.chat-widget.maximized {
    right: 0;
    margin-bottom: 30px;
    z-index: 11000;
}

.chat-widget.plivo.minimized {
    right: 0;
    bottom: -23%;
}

span.spiner-custom-size-8 {
    width: 8px;
    height: 8px;
}

.price-end {
    width: 100%;
    text-align: end;
}

.invoice .title {
    font-size: 13px;
}

.paymentSetting tr th:last-child,
.paymentSetting tr td:last-child {
    text-align: center;
}

.radio-label-custom label.form-check-label {
    padding-left: 5px;
}

ul.PipeLineStatus.list-group li.list-group-item {
    border-radius: 8px 8px 0 0;
}

.time-slots-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
    gap: 0;
    margin: auto;
}

.time-slot {
    padding: 5px;
    border-radius: 1px;
    text-align: center;
}

.time-slot label.btn-sm.btn-md {
    min-width: 100px;
}

a.fc-timegrid-event.fc-v-event.fc-event.fc-event-start.fc-event-end {
    background: none;
    border: 1px solid white;
    border-radius: 3px;
    width: 100%;
    box-shadow: none;
    margin: 0;
    padding: 0;
    height: auto;
}

.filter-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 2px;
    margin: auto;
}

.custom-witdth.dropdown button {
    width: 100%;
    justify-content: start;
}

.hide-caret::after {
    display: none;
}

/* .form-control,
.form-select {
    padding: 0.125rem 0.65rem;
}

.form-label {
    font-size: 0.8rem;
} */

/* .choices .choices__inner {
    min-height: 1.875rem;
    padding: 0.125rem 0.65rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    align-items: center;
} */

.folder {
    background: #1b468d;
}

.folder button:hover {
    text-decoration: underline;
}

.name-checkbox {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 10px;
}

.attachment .action-resource {
    display: flex;
}

.attachment .dropzone {
    min-height: 100px;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
}

.lead-image button.img-upload-lead {
    position: absolute;
    top: 27px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 40px;
    width: 40px;
    display: none;
    color: #478ffc;
    background-color: #e3eeff;
    border-color: #e3eeff;
}

.lead-image:hover button.img-upload-lead {
    display: block;
}

.width-250 {
    width: 250px;
}

.list-email .nk-ibx-item-message,
.list-sms .nk-ibx-item-message,
.list-note .nk-ibx-item-message,
.list-call .nk-ibx-item-message {
    white-space: normal;
}
.form-control.form-control-color {
    padding: 9px 9px;
    width: 100%;
}
.SearchBtn {
    position: absolute;
    background: transparent;
    left: 9px;
    top: -2px;
    z-index: 11
}
.SearchBox input {
    padding: 0.125rem 0.65rem 0.125rem 1.5rem;
}
.gray-bg {
    background: #eee;
}
.btn-outline-primary:hover{
    background-color: #233241;
    border-color: #233241
}
.fc-timegrid-event .fc-event-time {
    font-size: .85em;
    margin-bottom: 1px;
    white-space: nowrap;
    /* text-overflow: ellipsis; */
    width: 100%;
   }
   .form-control, .form-select{
    padding: .3rem 0.5625rem;
}
.choices__inner{
    padding: .3rem 0rem;
    min-height: auto;
}
.choices {
    margin: 0;
}
.LeadListTable .css-13cymwt-control, .LeadListTable .css-t3ipsp-control{
    min-height: auto;
}
.card .table > :not(caption) > * > *:first-child {
    padding-left: 0.75rem;
}
#chatWrap {
    margin-bottom: 35px;
}
.CategoryService .choices__inner, .sm .choices .choices__inner, .filter-edit.fixPadding .choices__inner {
    padding: .3rem 0.4625rem;
}
.SectionHeading {
    background: #f9f9f9;
    padding: 5px 10px;
    border-bottom: 1px solid #e5e7eb;
}
table tr {
    vertical-align: middle;
}

.choices[data-type*=select-one]:after {
    color: #222;
    font-size: 19px;
}
.chatBodyClasses button.btn.btn-outline-secondary {
    border: 1px solid #d2d4e4;
    color: #999;
}
.sms-responder .choices__inner {
    margin-bottom: 15px;
}
.token-button .choices .choices__inner {
    margin: 0;
}