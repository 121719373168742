::-webkit-scrollbar {
    width: 5px;
    background: #eee;
    height: 5px
}

::-webkit-scrollbar-track {
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: #ccc;
}

.mt-30 {
    margin-top: 30px;
}


.mr-5 {
    margin-right: 5px;
}

.nk-main.all .nk-header-fixed+.nk-content {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.nk-content {
    padding: 2.5rem 0 0.8rem;
}

.nk-header-fixed+.nk-content {
    margin-top: 2.3rem;
}

/*.rdt_TableRow:nth-child(even) {
    background: #f8f8f8;
}*/

.data-table-wrapper .rdt_TableCol_Sortable {
    color: #000;
    font-size: 12px;
}

.rdt_Table {
    border: 1px solid #eee;
    /*border-bottom: 0;*/
}

.data-table-top {
    padding: 1.25rem 0;
}

.data-table-wrapper .rdt_TableCol,
.rdt_TableBody .rdt_TableRow .rdt_TableCell {
    border-right: 1px solid #eee;
    /* text-align: center; */
    /* line-height: normal;
    padding: .1rem 0.75rem; */
}

.items-lists .choices {
    margin: 0;
}

.sm .choices .choices__inner {
    min-height: 1.875rem;
    padding: 0.125rem 0.65rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    align-items: center;
}

.sm .choices .choices__list--single .choices__item {
    padding: 0;
    align-items: center;
}

.items-lists .table> :not(caption)>*>* {
    padding: 0.5rem 0 0.5rem 0.5rem;
}

.card .items-lists .table> :not(caption)>*>*:first-child {
    padding-left: 0
}

.rdt_TableBody .rdt_TableRow:nth-child(even) {
    background: #f9f9f9;
}

.data-table-wrapper .rdt_TableCol:last-child,
.rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {
    border-right: 0;
}

.dropdown-content ul.link-list.link-list-hover-bg-primary li a {
    width: 100%;
    display: flex;
    justify-content: left;
}

.chatBodyClasses .nk-schedule-content .p {
    font-size: 13px;
}

td.border-width-0 {
    border-width: 0;
}

td.bs-table-bg-type {
    --bs-table-bg-type: tranparent !important;
}

/* .data-table-wrapper .rdt_TableCol .rdt_TableCol_Sortable {
    justify-content: center;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell {
    justify-content: center;
}

.data-table-wrapper .rdt_TableCol:last-child .rdt_TableCol_Sortable {
    justify-content: center;
} */
.data-table-wrapper .rdt_TableCol:last-child .rdt_TableCol_Sortable {
    justify-content: center;
}

.nk-block-head {
    margin-bottom: 0.6rem;
}

h4.user-label {
    text-transform: capitalize;
}

.main-parent .sub-parent .form-check {
    background: transparent;
}

.main-parent .form-check {
    background: #eee;
    padding: 10px
}

.main-parent {
    padding: 0;
    border-bottom-color: transparent;
}

.main-parent .list-group-horizontal .form-check {
    background: transparent;
}

.main-parent:last-child {
    border-bottom-color: #00000020
}

.main-parent .list-group-horizontal .sub-parent .list-group.list-group-horizontal .form-check label.form-check-label {
    font-weight: normal;
}

.main-parent .list-group-horizontal .sub-parent .form-check label {
    font-weight: 600;
}


/* Add this CSS in a separate .css or .scss file */
.progress-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.step {
    text-align: center;
    flex: 1;
    position: relative;
}

.step-count {
    display: block;
    width: 30px;
    height: 30px;
    border: 2px solid #1b468e;
    /* Circle border color */
    border-radius: 50%;
    margin: 0 auto 10px;
    line-height: 30px;
    font-size: 16px;
    color: #1b468e;
    /* Circle text color */
    background-color: #fff;
    /* Circle background color */
}

.step-name {
    font-size: 14px;
    color: #555;
}

.btn-link {
    color: #1b468e
}

.step.active .step-count {
    background-color: #1b468e;
    /* Active circle background color */
    color: #fff;
    /* Active circle text color */
}

/* Add more styling as needed */

/* Example: Style the progress bar */
.progress {
    height: 8px;
    background-color: #f1f1f1;
    /* Progress bar background color */
}

.progressBar {
    height: 8px;
    background-color: #007bff;
    /* Active progress bar color */
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.token-button {
    display: grid;
    grid-template-columns: 75% 24%;
    grid-gap: 1%;
}

.token-button .choices {
    margin-bottom: 0px;
}

/* chat-widget.css */
.chat-widget {
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 350px;
    height: 80%;
    background: #fff;
    transition: right 0.3s;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 2;
}

.chat-content {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.chat-widget.minimized {
    right: -330px;
    /* Adjust this value based on the width of your minimized state */
}

.chat-widget.maximized {
    right: 0;
}

.chat-icon {
    font-size: 24px;
    cursor: pointer;
}

.event-tree {
    /*min-width: 5200px;
    text-align: center;*/
    position: relative;

}

.event-tree:before {
    border-left: 2px dashed #9b9b9b;
    bottom: -20px;
    content: "";
    height: 22px;
    left: 50%;
    position: absolute;
    width: 0;
}

ul.tree-point li:before {
    border-left: 2px dashed #9b9b9b;
    content: "";
    height: 14px;
    left: 50%;
    position: absolute;
    top: 0;
    width: 0;
}

ul.tree-point li {
    padding-top: 18px;
    text-align: center;
    position: relative;
}

ul.tree-point li ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    position: relative;
    text-align: center;
}

/* Define styles for the minimized and maximized chat content */
.event-tree h5 {
    border: 2px dashed #9b9b9b;
    display: inline-block;
    line-height: 50px;
    min-height: 50px;
    width: 250px;
}

li.plus_outer.startNow button {
    width: 18px;
    height: 18px;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    padding: 0;
    background: #00002a;
    font-weight: 600
}

li.plus_outer.startNow button em.icon.ni.ni-cross {
    line-height: normal;
}

li.plus_outer.startNow button.plus {
    border: 1px dashed #ccc;
    border-radius: 50%;
    height: 25px;
    line-height: 20px;
    padding: 0;
    text-align: center;
    width: 25px;
    background: transparent;
    color: #00002a;
}

.sb_button,
a.plus {
    display: inline-block;
}

.sb_button .sb_button_inner.btn-primary.sb_editable {
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    line-height: 25px;
    min-width: 90px;
    padding: 5px;
}

.sb_button_inner.sb_editable {
    background: #3a3b3d;
    color: #fff;
    padding: 0 8px;
    border-radius: 5px;
}

.automation-action .fade:not(.show) {
    display: none;
}

.btn-primary {
    background-color: #1b468e;
    border-color: #1b468e
}

.btn-primary:hover {
    background-color: #233241;
    border-color: #233241
}

.btn-light:hover,
.btn-outline-light:hover {
    background-color: #2e314a;
    color: #fff
}

.card-auth-mh {
    min-height: unset;
}

.data-table-head-light .rdt_TableHeadRow {
    background-color: #f9f9f9;
    border-bottom-color: #eee;
}

.data-table-wrapper .rdt_TableCol,
.data-table-wrapper .rdt_TableCell {
    padding: 0.2rem 0.75rem;
}

.form-check-input {
    width: 1rem;
    height: 1rem
}

/*.data-table-wrapper .rdt_TableCell {
    padding: 0.5rem 0.75rem;
    line-height: normal;*/
/*white-space: nowrap;
}*/
vh {
    width: 1px;
    background: #eee;
    margin: 0 5px;
    position: static;
    min-height: 20px;
}

.link-list li a {
    padding-top: 0;
    padding-bottom: 0;
}

.link-list li a:hover,
.link-list li .btn:hover {
    background-color: transparent;
}

.nk-sidebar-head {
    background: #fff;
    padding: 0.5rem 0.375rem;
}

.logo-wrap {
    height: 40px;
}

.table>tbody td {
    background: transparent;
    font-size: 14px;
}

table.GroupTable th {
    padding: 0.2rem 0.75rem;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

/*.nk-block .card-gutter-md.card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
}*/
/* .LeadListTable .sc-hKwDye:nth-child(3) {
    white-space: nowrap;
} */
.nk-app-root.sidebar-compact .logo-wrap {
    display: flex;
    align-items: center;
}

.nk-app-root.sidebar-compact .logo-wrap img {
    max-height: 20px;
}

.is-theme .nk-menu .nk-menu-link {
    color: #ababab;
    opacity: 1;
    border-bottom: 1px solid #333;
}

.is-theme .nk-menu .nk-menu-sub .active>.nk-menu-link {
    color: #fff;
    background: transparent;
}

.nk-sidebar,
.nk-sidebar-head {
    width: 180px
}

[data-sidebar-collapse=lg] .nk-sidebar+.nk-wrap>.nk-header-fixed {
    left: 180px;
}

[data-sidebar-collapse=lg] .nk-sidebar+.nk-wrap {
    padding-left: 180px;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
    flex: none;
}

.nav-fill .nav-item .nav-link.active {
    background: transparent;
    border: 0;
    position: relative;
}

.nk-sidebar-brand {
    justify-content: center;
}

.nk-menu-link {
    padding: 0.625rem 1rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: transparent;
}

.active>.nk-menu-link::after {
    background-color: #1b468e;
}

.nav-tabs-s1 .nav-link.active {
    color: #1b468e;
}

.nav-tabs-s1 .nav-link::after {
    background-color: #1b468e;
    width: 40px;
    margin: auto;
}

.nav-fill .nav-item .nav-link.active:after {
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    position: absolute;
    content: "";
    background-color: #1b468e;
    width: 100%;
    opacity: 1;
    transform: scale(1);
    transition: 0.2s;
}

.MaxWidthForm {
    max-width: 500px;
}

.nk-menu li.nk-menu-item {
    position: relative;
}

nav.nk-header-menu.nk-navbar span {
    font-size: 14px;
}

.nk-menu li.nk-menu-item.has-sub.active a span {
    color: #fff;
}

.nk-menu li.nk-menu-item.has-sub.active ul.nk-menu-sub a span {
    color: unset;
}

.nk-sidebar.is-theme {
    background-color: #1b1b1b;
}

.active>.nk-menu-link {
    background-color: rgba(95, 56, 249, 0.1);
}

.nk-menu-item .nk-menu-sub li.nk-menu-item {
    list-style: disc;
    color: #ababab;
}

.nk-menu-item .nk-menu-sub li.nk-menu-item a.nk-menu-link {
    padding-left: 0;
}

.nk-menu-item .nk-menu-sub {
    margin-left: 2.5rem;
}

.nk-menu-item .nk-menu-sub li.nk-menu-item.active.current-page {
    color: #fff;
}

.nk-menu-item .nk-menu-sub li.nk-menu-item {
    border-bottom: 1px solid #333;
    line-height: normal;

}

.nowrap-table {
    white-space: nowrap;
}

.noWordBreak .rdt_TableBody .rdt_TableRow .rdt_TableCell {
    word-break: normal;
}

.is-theme .nk-menu li.nk-menu-item:last-child .nk-menu-link {
    border-bottom: 0;
}

.nk-menu {
    padding: 0;
}

/*.form-control, .form-select {
    padding: 0.7rem 1.125rem;
    line-height: 1rem;
}*/
span.currentLocation {
    color: #999;
}

nav.nk-header-menu.nk-navbar span.LocationName {
    background: #f1f1f1;
    color: #000;
    padding: 0px 5px 0px 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.btn-check:checked+.btn {
    background: #1b468e;
    border-color: #1b468e;
    color: #fff
}

.btn-check+.btn:hover {
    border-color: #1b468e !important;
    background-color: #1b468e;
    color: #fff
}

.AppoinmentLogo img.calendar-logo {
    max-width: 110px;
    max-height: 55px;
}

.AppointmentHeader {
    border-bottom: 1px solid #eee;
}

.AppoinmentLogo {
    text-align: center;
}

/* .fc-timegrid-event:has(.short_event) {
    position: static;
} */
.e-appointment-create td.fc-daygrid-day {
    cursor: pointer;
}

td.fc-daygrid-day.disabled-cell,
td.fc-daygrid-day.fc-day-past {
    background: #fcf8e3;
    cursor: not-allowed;
}

.e-appointment-create .fc-daygrid-day-number.selected-date {
    background: #404040;
    width: 31px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    color: #fff;
}

.e-appointment-create .slots .slot label.btn {
    min-width: 100px;
}

.chatBodyClasses {
    box-shadow: none;
    border-radius: 0;
    background: #fff;
    min-height: calc(100vh - 130px);
    border: 1px solid #eee;
}

.card.card.list-invoice {
    background: #f9f9f9;
}

.card.card.list-invoice .card-body {
    padding: 0rem 1rem 0.25rem 1rem;
}

.amount-wrap .amount {
    font-size: 13px;
}

.chatBodyClasses .simplebar-content .tab-content {
    max-height: calc(100vh - 195px);
    min-height: calc(100vh - 195px);
    overflow: auto;
}

.chatBodyClasses .nk-schedule-item-inner {
    display: flex;
    width: 100%;
}

.UserDetailDialer {
    box-shadow: none;
    border-radius: 0;
    background: #fff;
    min-height: calc(100vh - 130px);
    border: 1px solid #eee;
}

.UserDetailDialer .accordion-body {
    max-height: calc(100vh - 385px);
    overflow-x: hidden;
    overflow-y: auto;
    min-height: calc(100vh - 385px);
}

.UserDetailDialer .accordion-body .card {
    box-shadow: none;
}

.chatBodyClasses .nk-schedule-content {
    border: 1px solid #d2d4e4;
    border-radius: 0px;
    width: 98%;
    background: #f9f9f9;
    margin-bottom: 10px;
    border-radius: 0;
    padding: 0.3rem .5rem;
}
.text-bg-secondary-soft {
    padding: 3px 5px;
}
.nk-ibx-item-elem {
    padding: 1rem 0rem;
}
.badge {
    padding: 4px;
}
.chatBodyClasses .nav-tabs-s1.mb-3.nav.nav-tabs {
    background: #fff;
    padding: 0 10px;
    border-radius: 0;
}

.chatBodyClasses .simplebar-content {
    padding: 0 !important;
}

.UserDetailDialer ul.ActionButtons li button {
    background: #f4511e;
    border-radius: 50%;
    color: #fff;
    font-size: 13px;
    width: 2rem;
    height: 2rem;
}

.UserDetailDialer ul.ActionButtons li {
    padding: 0 3px;
}

.UserDetailDialer ul.ActionButtons li button.SMSButton {
    background: #79b502;
}

.UserDetailDialer ul.ActionButtons li button.EmailButton {
    background: #5ab7ab;
}

.btn-zoom:before {
    background-color: transparent;
}

.UserDetailDialer ul.ActionButtons li button:hover {
    background: #1b468e;
}

.chatBodyClasses .nk-message {
    padding: 0 0 0 1.5rem;
}

.is-you .nk-message-content {
    max-width: calc(100% - 0rem);
}

.list-other:before,
.list-email:before,
.list-call:before,
.list-sms:before,
.list-mms:before,
.list-rvm:before,
.list-note:before,
.list-call:before,
.list-appointment:before {
    width: 26px;
    height: 26px;
    content: "\ebcc";
    position: absolute;
    background: #ff6c60;
    left: -23px;
    border-radius: 3px;
    box-shadow: 0 0 1px #ccc;
    z-index: 2;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    /* font-family: FontAwesome; */
    font-family: Nioicon !important;
    top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-other:after,
.list-email:after,
.list-call:after,
.list-sms:after,
.list-mms:after,
.list-rvm:after,
.list-note:after,
.list-call:after,
.list-appointment:after {
    width: 19px;
    height: 1px;
    content: '';
    position: absolute;
    background: #c2c1c1;
    left: 4px;
    top: 18px;
    z-index: 1;
}

.list-other,
.list-sms,
.list-mms,
.list-email,
.list-rvm,
.list-note,
.list-call,
.list-appointment {
    position: relative;
    margin-left: 30px;
}

.list-email:before {
    content: "\ead9";
    background-color: #5ab7ab;
}

.list-call:before {
    content: "\e98f";
    background-color: #f4511e;
}

.list-sms:before,
.list-mms:before {
    content: "\ec9f";
    background-color: #79b502;
    font-family: "Nioicon" !important;
}

.list-rvm:before {
    content: "\ea44";
    background-color: #F87B5B;
}

.list-note:before {
    content: "\eb14";
    background-color: #948986;
}

.list-appointment:before {
    content: "\e988";
    background-color: #addfff;
    color: #000;
}

.UserDetailDialer .accordion-item {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
}

.UserDetailDialer ul.ActionButtons .CallActionDropDown li {
    padding: 5px 0px;
}

.UserDetailDialer .bio-block .form-group label.form-label {
    background: #f9f9f9;
    min-width: 115px;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    min-height: 40px;
    padding: 0 5px;
}

.UserDetailDialer .bio-block .form-group {
    display: flex;
    margin: 0 0 -1px 0;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 0;
    overflow: unset;
}

ul.PipeLineStatus span.border-bottom {
    border-color: #e1e1e1 !important;
}

ul.PipeLineStatus .activePipeleine {
    color: #fff;
}

.StatusPipe button {
    font-size: 12px;
}

.UserDetailDialer .bio-block .form-group .form-control-wrap {
    margin: 0 0px 0 5px;
    width: 100%;
    min-height: 30px;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.nav-tabs-s1 .nav-item {
    padding-right: 1.2rem;
}

.SaveButton {
    height: 18px;
    width: 15px;
    line-height: normal;
    flex-shrink: 0;
    padding: 0;
    background: transparent;
    border: 0;
    color: #1b468e;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.UserDetailDialer .bio-block .form-group .form-control-wrap input,
.UserDetailDialer .bio-block .form-group .form-control-wrap select {
    height: 35px;
    min-width: 100%;
    padding: 0.5625rem 0.5rem;
}

.UserDetailDialer .bio-block .CustomFields .form-group .form-control-wrap .form-group .form-control-wrap input.form-control {
    min-width: 160px;
}

.UserDetailDialer .bio-block .form-group .form-control-wrap select {
    padding: 0.10rem 0.5rem;
}

.UserDetailDialer .bio-block .CustomFields .form-group {
    align-items: flex-start;
}

.UserDetailDialer .bio-block .CustomFields .form-group .form-control-wrap .form-group label.form-label {
    background: transparent;
    min-width: 170px;
    height: auto;
    min-height: auto;
}

.UserDetailDialer .bio-block .CustomFields .form-group .form-control-wrap .form-group {
    border: 0;
    display: flex;
}

.UserDetailDialer .media.media-middle.media-big.media-circle {
    border: 5px solid #ddd;
    width: 6rem;
    height: 6rem;
    box-shadow: none;
    margin-bottom: 15px;
}

/* .UserDetailDialer .bio-block .form-group .form-control-wrap button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
} */
button.btn-icon.EditButton.btn.btn-zoom.btn-md {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.UserDetailDialer .bio-block .CustomFields .form-group button.btn-icon.EditButton.btn.btn-zoom.btn-md {
    height: 40px;
}

.UserDetailDialer .bio-block .CustomFields .form-group .form-control-wrap .form-group .form-control-wrap {
    width: auto;
}

.ActionBtnEdit {
    position: absolute;
    top: 0;
    right: 0px;
    display: flex;
    align-items: center;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
}

.UserDetailDialer .choices {
    margin: 0;
}

.UserDetailDialer .choices__inner {
    padding: 0.2rem;
    align-items: center;
}

.PaymenyView .modal-content {
    overflow: hidden;
}

.modal-header button.btn-close {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
}

.PaymenyView table th {
    font-weight: 500;
    color: #000;
    font-size: 14px
}

.PaymenyView table td {
    font-size: 14px;
}

span.large {
    margin: 0 10px 0 0px;
}

.UserDetailDialer .bio-block .form-group .form-control-wrap .EditButton {
    display: none;
}

.UserDetailDialer .bio-block .form-group .form-control-wrap:hover .EditButton {
    display: block;
}

.fc-toolbar-chunk button.fc-today-button {
    padding: 1px 5px 1px 5px;
}

.e-appointment-create .list-group .list-group-item:hover {
    background: #ccc;
    cursor: pointer;
}

.popover {
    min-width: 330px;
    max-width: unset;
}

.ActionButtons.SocialIcons {
    position: absolute;
    right: 20px;
    top: 10px;
}

.ProfilePipelineStatus {
    width: 20%;
}

.StatusPipe {
    font-size: 13px;
}

.PipelineUserName {
    margin-top: -55px;
}

.UserDetailDialer h2.bg-light.accordion-header button.accordion-button:after {
    display: none;
}

#custom-tabs-example-tabpane-my-number .rdt_TableCell:last-child,
#custom-tabs-example-tabpane-my-number .rdt_TableCol:last-child {
    min-width: 220px;
}

.doc-list td {
    white-space: nowrap;
}

.doc-lists .form table.table th,
.request-doc .form table.table th {
    font-size: 14px;
    padding-left: 0;
}

.doc-lists .form table.table td {
    padding-left: 0;
}

.doc-request table.table td {
    padding-left: 0;
    font-size: 12px;
}

.folders-list,
.doc-list table.table {
    font-size: 12px;
}

.calendar-create .form-control-color,
.calendar-edit .form-control-wrap input {
    padding: 0.125rem;
}

.form-control-color {
    height: 2.1rem;
}

ul.PipeLineStatus button {
    font-size: 13px;
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.form-control-icon {
    height: 100%;
}

ul.PipeLineStatus button:before {
    content: "\e980";
    font-family: Nioicon !important;
    font-size: 11px;
    float: left;
    margin: 0px 5px 0 0px;
}

h6,
.h6 {
    font-size: 0.835rem;
}

.image-gallery {
    max-height: 150px;
    overflow: hidden;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    min-height: auto;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    background: #eee;
    border-radius: 4px;
    flex-direction: column;
    position: relative;
    min-height: 150px;
}

.image-gallery input[type="checkbox"] {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background-color: #ddd;
    display: inline-block;
    z-index: 11;
    border: 0;
    /* opacity: 0; */
}

.ImgEditandDelete {
    width: 100%;
    color: #fff;
    text-align: center;
    background: #000;
    border-radius: 0 0 4px 4px;
}

.ImgEditandDelete {
    display: flex;
    flex-direction: column;
}

.ImgEditandDelete span {
    font-size: 12px;
}

.ImgEditandDelete button {
    font-size: 12px;
}

.edit-form {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: #ccc;
}

.MediaManegerPopup {
    max-height: calc(100vh - 125px);
    overflow: auto;
}

.UserDetailDialer .media.media-middle.media-big.media-circle img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.center.card-img-overlay {
    background: rgba(0, 0, 0, .6);
}

.nk-menu-toggle::before {
    right: 0.5rem;
}

.sequence-table {
    max-height: 283px;
    min-height: 283px;
    overflow: auto;
}

.TemplatesTable {
    max-height: 585px;
    overflow: auto;
}

.TemplatesTable.SMS table.table {
    max-height: 200px;
    overflow: auto;
}

.TextAreaBox .tox.tox-tinymce {
    width: 100%;
}

.sequence-table table {
    vertical-align: middle;
}

.choices__list--dropdown {
    word-break: unset;
}

.Select-min-height .choices__list--dropdown {
    height: 100px;
    overflow: auto;
}

.choices__list--dropdown .choices__item--selectable:after {
    display: none;
}

.choices__list--dropdown .choices__item {
    padding: 0.3rem 0.3rem;
    font-size: 12px;
}

.choices__list--dropdown.is-active {
    z-index: 11;
}

.btns.ViewDeleteBtn button {
    height: 16px;
    margin: -10px 0 0 0;
}

.btns.ViewDeleteBtn {
    height: 16px;
    display: none;
}

.list-invoice:hover .btns.ViewDeleteBtn {
    display: block;
}

.offcanvas-body iframe#leadIframe {
    height: calc(100vh - 30px) !important;
}

.offcanvas-body {
    overflow-x: hidden;
}

.iframeArea .bio-block {
    height: calc(100vh - 115px);
}

.EditAppointment {
    height: calc(100vh - 42px);
    overflow: auto;
}

.EditAppointment .choices__list--single .choices__item {
    padding: 0 0.5625rem;
}

.CloseBtn {
    /* position: absolute; */
    right: 14px;
    color: #fff;
    top: 14px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    float: right;
}

.CloseBtn button {
    font-size: 20px;
}

.offcanvas-body iframe {
    height: calc(100vh - 50px);
}

.paymentSetting table tr {
    vertical-align: middle;
}

.input-group-text-custom select.form-control.custom-boder {
    border-radius: 0 4px 4px 0px;
}

.doc-lists .drop-area .dropzone {
    min-height: auto;
    padding: 0;
    border: 0;
    justify-content: flex-start;
}

.doc-lists .drop-area .dz-message {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.doc-lists .folder .action-attachment .dropdown.btn-group {
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.document-file a {
    font-size: 11px;
}

.doc-lists .dropzone {
    min-height: 80px;
}

.doc-lists .dropzone span.dz-message-text {
    font-size: 14px;
}

.folder .name-checkbox {
    margin-left: 5px;
}

.eqalheight {
    min-height: 97%;
}

.folder .name-checkbox button {
    font-weight: 600;
}

.uplodaed-doc-lists .eqalheight {
    min-height: 88%;
}

.uplodaed-doc-lists .document-file li {
    line-height: normal;
}

.chatBodyClasses .nk-schedule-content .p td img,
.chatBodyClasses .nk-schedule-content .p video,
.chatBodyClasses .nk-schedule-content .p img {
    width: unset !important;
    min-width: unset !important;
    max-width: 100% !important
}

.chatBodyClasses .nk-schedule-content .p div {
    min-width: unset !important;
}

.document-generate .choices {
    width: 100%;
}

.tox-dialog__body-content .tox-form .tox-form__group.tox-form__group--stretched .tox-textarea-wrap .tox-textarea {
    height: 65vh;
}

.tox-collection__item-label * {
    background-color: transparent !important;
}

.btns-nav button.btn-sm {
    margin-left: 10px;
}

.editor-tabs {
    width: 100%;
}

.extraEditorButton {
    position: absolute;
    right: 0;
}

.design_type {
    text-align: center;
    border: 1px solid #e5e5e5;
    padding: 5px 0;
}

.design_type img {
    height: 60px;
    min-height: 60px
}

.MainBg {
    background: url(../images/main-bg.jpg) no-repeat;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
}

.FunnelBody {
    background: rgba(255, 255, 255, .8);
    min-height: 450px;
    border-top: 1px solid #B5B9BC;
    border-bottom: 1px solid #B5B9BC;
}

.UserFormFunnel.boxed {
    min-width: 700px;
    max-width: 700px;
    margin: 0 auto;
}

.UserFormFunnel select,
.UserFormFunnel input {
    height: 50px;
    border: 4px solid #052a46;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    margin: 0;
    font-size: 22px;
    padding: 0 15px;
}

.UserFormFunnel select {
    appearance: auto;
}

.UserFormFunnel label {
    font-weight: bold;
    font-size: 18px;
    /* margin-bottom: 8px; */
}

.UserFormFunnel button.button.button--tamaya.button--border-thick {
    display: flex;
    margin: 30px auto 0;
    font-size: 26px;
    color: #fff;
    padding: 20px 40px;
    border-radius: 8px;
    -webkit-box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
    box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
    font-family: Roboto Condensed, sans-serif;
    line-height: 1;
    text-shadow: 0 -1px 1px rgba(175, 49, 95, .7);
    -webkit-transition: background-color .2s ease-in-out, -webkit-transform .1s ease-in-out;
    -moz-transition: background-color .2s ease-in-out, -moz-transform .1s ease-in-out;
    -o-transition: background-color .2s ease-in-out, -o-transform .1s ease-in-out;
    transition: background-color .2s ease-in-out, transform .1s ease-in-out;
    transform: initial;
    opacity: 1;
    border: 0;
    transition: all .5s ease-in-out;
    background: #1b468d;
    -webkit-box-shadow: 0 15px 25px rgba(0, 0, 0, .5);
    -moz-box-shadow: 0 15px 25px rgba(0, 0, 0, .5);
    box-shadow: 0 15px 25px rgba(0, 0, 0, .5);
    padding: 20px 40px;
}

.UserFormFunnel button.button.button--tamaya.button--border-thick:hover {
    background: #000;
}

.UserFormFunnel button.button.button--tamaya.button--border-thick .ni {
    /* transform: scale(1.5); */
    font-weight: 900;
    margin: 0 0 0 10px;
    transition: all 1s ease-in-out;
    /* opacity: 0; */
}

.UserFormFunnel button.button.button--tamaya.button--border-thick:hover .ni {
    transform: scale(1.5);
}

.FunnelFooter img {
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -.1em .1em .1em;
    line-height: 1;
    vertical-align: middle;
}

.SecureIcon h4.poweredby {
    font-size: 12px;
    color: #fff;
    font-weight: normal;
}

.SecureIcon {
    color: #fff;
}

/* .form-control:not([type=file]):read-only{
    background-color: #fff;
} */
.UserFormFunnel .form-check-input:checked[type=radio],
.UserFormFunnel .form-check-input[type=radio],
.UserFormFunnel .form-check-input[type=checkbox] {
    width: 30px;
    height: 30px;
    border: 2px solid #052a46;
    padding: 0;
}

form.UserFormFunnel .form-check {
    align-items: center;
    margin-bottom: 10px;
}

.FormRightSide .form-control.form-control-color {
    padding: 3px;
    width: 100%;
}

.LeadListTable .css-13cymwt-control,
.LeadListTable .css-t3ipsp-control {
    max-height: 38px;
    overflow: auto;
    min-width: 160px;
}

.LeadListTable .css-1xc3v61-indicatorContainer,
.LeadListTable .css-1u9des2-indicatorSeparator {
    display: none;
}

.LeadListTable .css-15lsz6c-indicatorContainer:first-child {
    display: none;
}

.LeadListTable .lnndaO {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    /*white-space: nowrap;*/
}

/*.LeadListTable .fvAeSn{
    min-width: unset;
}*/
.LeadListTable .css-15lsz6c-indicatorContainer {
    position: absolute;
    right: 0;
    top: 0;
}

.table-edits .css-b62m3t-container DIV:first-child {
    overflow: visible;
}

.table-edits .css-b62m3t-container {
    font-size: 12px
}

.css-1jdh11-MenuPortal {
    font-size: 12px !important
}

.add-scroll {
    max-height: 300px;
    overflow: auto !important;
}

/* CustomTabs.css */
.LeadListTable.customized-width {
    max-width: calc(100vw - 305px);
}

.open-chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1050;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: white;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.open-chat-button i {
    font-size: 24px;
}

.chat-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 330px;
    max-height: 400px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* border-radius: 10px; */
    overflow: hidden;
    display: none;
    /* Initially hidden */
    flex-direction: column;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.chat-popup.show {
    display: flex;
    /* Show when active */
    opacity: 1;
    transform: translateY(0);
    z-index: 30;
}

.chat-header {
    background-color: #dc3545;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-header h5 {
    margin: 0;
}

.chat-header .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.chat-body {
    flex: 1;
    padding: 10px;
    background-color: #f9f9f9;
    overflow-y: auto;
}

.schedule-item {
    margin-bottom: 20px;
}

.schedule-item h6 {
    margin: 0;
    font-size: 16px;
    color: #007bff;
}

.schedule-item p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
}

.schedule-item p strong {
    color: #333;
}

.title-link,
.related-link {
    color: #007bff;
    text-decoration: none;
}

.title-link:hover,
.related-link:hover {
    text-decoration: underline;
}

.schedule-item {
    position: relative;
    /* Make sure the parent is positioned relative for absolute positioning of the button */
}

.dismiss-button {
    display: none;
    /* Hide button by default */
}

.schedule-item:hover .dismiss-button {
    display: inline-block;
    /* Show button on hover */
}

.nav-sm {
    position: relative;
}

.notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    padding: 0 4px;
    border-radius: 10px;
    font-size: 0.65rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 16px;
    line-height: 1;
    white-space: nowrap;
}

.tooltip-inner {
    font-size: 12px;
}

.nk-footer {
    background-color: #fff;
    margin-top: auto;
    border-top: 1px solid #e8e7ec;
    position: fixed;
    bottom: 0;
    /* width: 88%; */
    left: 0;
    right: 0;
    padding-left: 180px;
    z-index: 10;
    padding-top: 0;
    padding-bottom: 0;

}

.footer-items {
    border-right: 1px solid #cdd0d9;
    padding: 5px 18px 5px 5px;
    cursor: pointer;
}

.fc-daygrid-event {
    position: relative;
    white-space: unset;
    border-radius: 3px;
    font-size: 0.85em;
    font-size: var(--fc-small-font-size, 0.85em);
}

.form-check-input:only-child {
    margin-top: 3px;
    align-self: center;
    /* width: 35px; */
}

/* .SettingsWizard {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  } */
  
  .SettingsWizard h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    color: #337ab7;
  }
  
  .SettingsWizard .step {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .SettingsWizard .step h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
    color: #337ab7;
  }
  
  .SettingsWizard .step .description {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .SettingsWizard .step .actions {
    margin-top: 10px;
  }
  
  .SettingsWizard .step .actions button {
    background-color: #337ab7;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .SettingsWizard .step .actions button:hover {
    background-color: #23527c;
  }
  
  .SettingsWizard .step .actions button:last-child {
    margin-right: 0;
  }

  .wizard-step {
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    color: #495057;
    border-radius: 50px;
    padding: 10px 30px;
    font-weight: 500;
    transition: background 0.3s ease-in-out;
    margin: 0 10px;
  }
  .wizard-step.active, .wizard-step:hover {
    background: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
    color: white;
    box-shadow: none;
  }
  .wizard-btn {
    background: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
    border: none;
    color: white;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }
  .wizard-btn:hover {
    background: linear-gradient(135deg, #0056b3 0%, #007bff 100%);
    box-shadow: none;
  }
  .p-5 {
    background: white;
    border-radius: 10px;
    box-shadow: none;
  }
  h4 {
    font-weight: 700;
    color: #343a40;
  }
  .form-control-lg {
    border-radius: 50px;
    padding: 15px;
    font-size: 1rem;
  }

@media (max-width: 1520px) {
    .ProfilePipelineStatus {
        width: 30%;
    }
}

/* @media (min-width: 1400px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
} */

@media (max-width: 1366px) {
    .ProfilePipelineStatus {
        width: 32%;
    }

    .UserDetailDialer .accordion-body {
        max-height: calc(100vh - 381px);
        min-height: calc(100vh - 381px);
    }
}